import * as React from 'react'
import { LayoutBootstrap } from '../components/layoutBootstrap'
import "../styles/styles.css"
import { Container, Row, Col } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { useContext } from 'react'
import { LanguageContext } from '../components/language'
import { T } from '../translation/translate'



const CompanyPage = () => {
    const {lang, setLang} = useContext(LanguageContext);

return(
    <LayoutBootstrap>
        <Container fluid style={{backgroundColor: "#6ba7cf"}}>
            <Row className='no-gutters'>
                <Col style={{padding:0}}>
                    <StaticImage src={"../images/pages/companies.png"} alt="business" className="image"/>
                </Col>    
            </Row>    
        </Container>   

        <Container fluid className='oddcontainer'>


                    <Row className="oddcontainer">
                        
                        <Col>
                        <h1>{T(lang, {en:"For companies", nl:"Voor bedrijven"})}</h1>
                            <p>{T(lang,{en:"InterSECtion is always looking for fun and challenging collaborations with companies. In the past, we have organized workshops and crash cases with Trimble, SciComNL, Connekt, De Praktijk and many more. During these collaborations, our students have contributed to analyzing the communicative challenges within the companies. ",
                                        nl:"InterSECtion is altijd op zoek naar leuke en uitdagende samenwerkingen met bedrijven. Zo hebben wij in het verleden crash cases en workshops georganiseerd met onder andere Trimble, SciComNL, Connekt en De Praktijk, waarin onze studenten hebben bijgedragen met het analyseren van communicatieve uitdagingen binnen hun bedrijf. "
                                        }
                                )}
                            </p>
                            <p>{T(lang,{en:"InterSECtion has a lot to offer for companies. Often we do this in the form of a 'crash case'. Which entails that the company in question presents an actual communication-related problem within their organization and proposes that problem to the students. The students of this master have a unique combination of technical and communication related knowledge, analytical skills and creativity. Because of this combination, students can offer a fresh view on the communication-related challenge within the company. ",
                                        nl:"Voor bedrijven heeft InterSECtion vaak een hoop te bieden. Meestal doen we dit in de vorm van een ‘crash case’. Dat houdt in dat het bedrijf met een actueel communicate-gerelateerd probleem binnen de organisatie komt en die dan voorlegt aan SEC-studenten. Onze studenten hebben een unieke combinatie van technische en communicatie-gerelateerde kennis, analytische vaardigheden en creativiteit. Door deze combinatie kunnen de studenten een frisse blik op een communicatieve uitdaging binnen het bedrijf werpen.  "
                                        }
                                )}
                            </p>
                            <p>{T(lang,{en:"Concretely, this usually leads to a communication strategy, communication plan and/or a list of concrete creative ideas or tips that can be applied within the context of the case. This is a win-win: the students get acquianted with a 'real-life' case and further develop their skills, the company gets valuable new insights that can actually make a difference.",
                                        nl:"Concreet leidt dit meestal tot een communicateistrategie, -plan en/of een lijst van concrete creatieve ideeën of tips die binnen de casus toegepast kunnen worden. Een win-win dus: de studenten maken kennis met een case uit ‘het echte leven’ en ontwikkelen hun vaardigheden verder, het bedrijf krijgt waardevolle nieuwe inzichten die écht het verschil kunnen maken.                                     "
                                        }
                                )}
                            </p>
                            <p>{T(lang,{en:"Apart from this, InterSECtion offers a network of students to get in touch with for finding an internship, a graduation project or a job.",
                                        nl:" Hiernaast biedt InterSECtion een netwerk van studenten om mee in contact te komen voor een stage, afstudeerproject of baan.                                        "
                                        }
                                )}
                            </p>
                            <p>{T(lang,{en:"Do you think your company has a fun and interesting challenge or career opportunity for SEC-students? Then do not hesitate to get in touch with us via",
                                        nl:" Denkt u als bedrijf een leuke uitdaging of carrièremogelijkheid te hebben voor SEC-studenten, neem dan contact met ons op via                                      "
                                        }
                                )}
                                <a className="boldlink" to={"mailto:intersection@tudelft.nl"}> intersection@tudelft.nl</a>.
                            </p>
                        </Col>
                    </Row>
        </Container>
    </LayoutBootstrap>
)

}

export default CompanyPage